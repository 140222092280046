import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import RouteListing from "../components/routeListing"
import ModelListing from "../components/modelListing"

function pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      darkColor : lightColor;
  }

const IndexPage = () => {

    const productData = require('../../data/products.json');
    const products = productData.filter(({ }, i) => i < 6);

    const product = require('../../product.json');

    const _routes = product?.openapi?.paths || {};
    const routes = [];

    const definitions = product?.openapi?.components?.schemas || [];

    Object.keys(_routes).forEach(key => {

        const route = _routes[key];

        Object.keys(route).forEach(method => {

            routes.push({
                ...route.get,
                method,
                path: key
            });

        })

    })

    return <Layout>
        <section class="bg-white">
            <div style={{ background: pickTextColorBasedOnBgColorSimple(product.type.color, '#ffffff', '#333333') }}
                class="mx-auto max-w-screen-xl px-4 py-32 my-16 lg:flex lg:h-max lg:items-center rounded-xl"
            >
                <div style={{ borderColor: product.type.color, color: product.type.color, background: pickTextColorBasedOnBgColorSimple(product.type.color, '#ffffff', '#333333') }} class="text-white mx-auto border-4 max-w-xl text-left bg-primary p-8 rounded-xl ">
                    <div className="flex flex-row content-center justify-between ">
                        <h1 class="text-3xl font-extrabold sm:text-5xl">
                            {product.name}
                        </h1>
                        <div dangerouslySetInnerHTML={{ __html: product.logo.replace('class="w-5 h-5"', 'class="w-10 h-10"') }} />
                    </div>

                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        {product.short}
                    </p>
                </div>
            </div>
        </section>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div class="mx-auto h-full max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                        <h2 class="text-3xl font-bold sm:text-4xl">Routes</h2>
                        <p class="mt-4 text-gray-600">
                            {product.name} contains {routes.length} unique endpoints, or 'routes' as we like to call them. These routes manage all of the logic behind {product.name}- making it accessible to developers.
                        </p>

                        <div class="mt-8 flex flex-wrap justify-center lg:justify-start gap-4">
                            <a
                                class="block w-full rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-primary focus:outline-none focus:ring active:bg-primary sm:w-auto"
                                href="/routes"
                                style={{ background: product.type.color, color: pickTextColorBasedOnBgColorSimple(product.type.color, '#ffffff', '#333333') }}
                            >
                                All Routes
                            </a>
                        </div>
                    </div>

                    <div>
                        <div class="grid grid-cols-1 gap-4 mb-3">
                            {
                                routes.filter((i, n) => n < 3).map((item) => RouteListing({ ...item, href: `/products/${item.slug}` }))
                            }
                        </div>
                        { routes.length > 3 && <a className="text-slate-500 hover:underline" href="/routes">And {routes.length - 3} more...</a> }
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-16" id="features">
            <div class="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
                <div
                    class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16"
                >
                    <div>
                        <div class="grid grid-rows-1 grid-cols-2 gap-4 sm:grid-cols-3 sm:grid-rows-2 h-full">
                            {
                                Object.keys(definitions).filter((i, n) => n < 6).map((key) => {
                                    
                                    return ModelListing({ name: key, ...definitions[key] })
                                
                                })
                            }
                        </div>
                        { Object.keys(definitions).length > 6 && <a className="text-slate-500 hover:underline" href="/models">And {Object.keys(definitions).length - 6} more...</a> }
                    </div>
                    
                    <div class="mx-auto h-full max-w-lg text-center lg:mx-0 lg:text-left flex align-top flex-col">
                        <h2 class="text-3xl font-bold sm:text-4xl">Models</h2>
                        <p class="mt-4 text-gray-600">
                            Currently, {product.name} implements {Object.keys(definitions).length} models. These models store data that is then used for requests and responses on {product.name}'s {routes.length} endpoints.
                        </p>

                        <div class="mt-8 flex flex-wrap justify-center lg:justify-start gap-4">
                            <a
                                class="block w-full rounded bg-primary px-12 py-3 text-sm font-medium text-white shadow hover:bg-primary focus:outline-none focus:ring active:bg-primary sm:w-auto"
                                href="/products"
                                style={{ background: product.type.color, color: pickTextColorBasedOnBgColorSimple(product.type.color, '#ffffff', '#333333') }}
                            >
                                All Models
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout >
}

export const Head = () => {

    const product = require('../../product.json');

    return <Seo title={product.name} description={product.short} />

}

export default IndexPage
